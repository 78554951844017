import { ApiCommonSettingsFeatures, Countries } from '@temperworks/types'
import temperCountries, { Country } from '../composables/temperCountries'
import { ApiSettingsExternalResources } from '../interfaces/api/Settings'
import countrySpecificRoutesConfig from './../config/countrySpecificRoutes'

export const useCountryStore = defineStore('country-store', () => {
  const cookie = window.document.cookie.match(RegExp('(?:^|;\\s*)' + 'country' + '=([^;]*)'))
  const i18nCookie = window.document.cookie.match(RegExp('(?:^|;\\s*)' + 'i18n' + '=([^;]*)'))
  const countryArray = temperCountries().temperCountries
  const country = ref<string>('')
  const countryAlpha3 = ref<string>('')
  const countryAlpha2 = ref<string>('')
  const backend = ref<string>('')
  const currency = ref<string>('')
  const locale = ref<string>('')
  const externalResources = ref<ApiSettingsExternalResources>()
  const nuxtApp = useNuxtApp()
  const browserLang = navigator.language
  const changeCountry = ref<boolean>(false)
  const features = ref<ApiCommonSettingsFeatures>()
  const countrySpecificRoutes = ref<object>({})
  const availableLocales = ref<string[]>([])

  async function setCountryValues(countryCheck: Country) {
    country.value = countryCheck.country
    backend.value = countryCheck.backend
    currency.value = countryCheck.currency
    locale.value = countryCheck.i18nLocale
  
    if (!i18nCookie) {
      await nuxtApp.$i18n.setLocale(countryCheck.locale)
    }
  }

  async function initCountryStore() {
    if (cookie) {
      const countryCheck = countryArray.find(countryCheck => countryCheck.country === cookie[1])

      if (countryCheck) {
        await setCountryValues(countryCheck)
      }
    } else {
      let countryCode = Countries.NL

      if (browserLang.toLowerCase().includes('gb')) {
        countryCode = Countries.UK
      } else if (browserLang.toLowerCase().includes('fr')) {
        countryCode = Countries.FR
      }

      const hardCoded = countryArray.find(countryCheck => countryCheck.country === countryCode)

      if (hardCoded) {
        await setCountryValues(hardCoded)
      }
    }
  }


  function setCountry(name: any) {
    country.value = name
    countrySpecificRoutes.value = countrySpecificRoutesConfig[name]
  }
  function setBackend(url: string) {
    backend.value = url
  }
  function setCurrency(value: string) {
    currency.value = value
  }
  function setCountryChange() {
    changeCountry.value = !changeCountry.value
  }
  async function setLocale(value: string) {
    const localeCheck = countryArray.find(countryCheck => countryCheck.i18nLocale === value)

    if (!!localeCheck) {
      locale.value = value
      await nuxtApp.$i18n.setLocale(localeCheck.locale)
    }
  }

  function getCountrySettings() {
    return new Promise((resolve, reject) => {
      if (backend.value) {
        $fetch(backend.value + '/api/v4/settings/?include%5B%5D=routes&include%5B%5D=features', {
          method: 'GET'
        })
          .then((response: any) => {
            currency.value = response.currency_code
            countryAlpha3.value = response.country_code_iso_alpha3
            countryAlpha2.value = response.country_code_iso_alpha2
            availableLocales.value = response.available_locales
            countrySpecificRoutes.value = countrySpecificRoutesConfig[country.value]
            const externalResourcesResponse = response.external_resources
            Object.keys(externalResourcesResponse).forEach((key: string) => {
              externalResourcesResponse[key] =
                externalResourcesResponse[key]
                  ? externalResourcesResponse[key].replace('{language}', countryAlpha2.value).toLowerCase()
                  : externalResourcesResponse[key]
            })
            externalResources.value = externalResourcesResponse
            features.value = response.features
            resolve(response)
          })
          .catch(err => reject(err))
      } else {
        resolve(false)
      }
    })
  }

  return {
    country,
    countryAlpha3,
    countryAlpha2,
    externalResources,
    backend,
    changeCountry,
    currency,
    locale,
    setCountry,
    setBackend,
    setCountryChange,
    getCountrySettings,
    setCurrency,
    setLocale,
    features,
    countrySpecificRoutes,
    availableLocales,
    initCountryStore
  }
})
